<template>
  <div
    v-if="content"
    style="padding: 15px;"
  >
    <v-card
      class="mx-auto"
    >
      <v-img
        v-if="content.SiteImage"
        :src="content.SiteImage"
        height="200px"
        :style="{ 
          }"
      />

      <v-card-title>
        <div style="width:100%;" v-if="title && title.length > 0" v-html="title" class="siteTitle" />
        <span
          v-if="subtitle && subtitle.length > 0"
          class="grey--text subtitle-1"
          style="line-height: 1.2;font-weight: 400; color: rgba(0, 0, 0, 0.47); font-size: 12px !important;text-transform: uppercase;margin-bottom: 10px;"
          :style="{ 'color': `${config.ColorContent} !important` }"
        >
          {{subtitle}}
        </span>
      </v-card-title>

      <v-divider v-if="(content.SiteAddress && content.SiteAddress.length > 0) || (content.SiteLat && content.SiteLat.length > 0  && content.SiteLon && content.SiteLon.length > 0 )" style="margin-left: 15px;margin-right:15px;" />
      <v-layout wrap>
        <v-flex xs9 style="padding:15px 0 15px 15px">
          <span
            v-if="content.SiteAddress && content.SiteAddress.length > 0"
            style="font-weight: 500; color: rgba(0, 0, 0, 0.47); font-size: 12px;"
            :style="{ 'color': config.ColorContent }"
            @click="handleGoToMap(coordenates)"
          >
            {{$t('dtouch.site.address', locale)}} : 
          </span> 
          <span
            v-if="content.SiteAddress && content.SiteAddress.length > 0"
            style="text-decoration: none;"
            :style="{ 'color': config.ColorContent }"
            @click="handleGoToMap(coordenates)"
          >
            {{content.SiteAddress}}
          </span>
        </v-flex>
        <v-flex xs3 v-if="content.SiteLat && content.SiteLon && content.SiteLat.length > 0  && content.SiteLon.length > 0 " style="padding:20px 15px 20px 0;text-align: right">
          <v-btn class="mx-2" fab dark small :color="'#CCCCCC'"
            :elevation="1"
            @click="handleGoToMap(coordenates)"
          >
            <v-icon :color="'#FFFFFF'">mdi-map-marker</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>

      <v-divider v-if="content.SitePhone && content.SitePhone.length > 0" style="margin-left: 15px;margin-right:15px;" />
      <div v-if="content.SitePhone && content.SitePhone.length > 0" style="width:100%;padding:15px;">
        <span
          style="font-weight: 500; color: rgba(0, 0, 0, 0.47); font-size: 12px;"
          :style="{ 'color': config.ColorContent }"
        >
          {{$t('dtouch.site.phone', locale)}} : 
        </span> 
        <a :href="`tel:${content.SitePhone}`"
          style="text-decoration: none;"
          :style="{ 'color': config.ColorContent }"
        >
          {{content.SitePhone}}
        </a>
      </div>

      <v-divider v-if="content.SiteWeb && content.SiteWeb.length > 0" style="margin-left: 15px;margin-right:15px;" />
      <div v-if="content.SiteWeb && content.SiteWeb.length > 0" style="width:100%;padding:15px;">
        <span
          style="font-weight: 500; color: rgba(0, 0, 0, 0.47); font-size: 12px;"
          :style="{ 'color': config.ColorContent }"
        >
          {{$t('dtouch.site.web', locale)}} : 
        </span> 
        <a :href="`${content.SiteWeb}`"
          target="_blank"
          style="text-decoration: none;"
          :style="{ 'color': config.ColorContent }"
        >
          {{content.SiteWeb}}
        </a>
      </div>

      <v-divider v-if="content.SiteEmail && content.SiteEmail.length > 0" style="margin-left: 15px;margin-right:15px;" />
      <div v-if="content.SiteEmail && content.SiteEmail.length > 0" style="width:100%;padding:15px;">
        <span
          style="font-weight: 500; color: rgba(0, 0, 0, 0.47); font-size: 12px;"
          :style="{ 'color': config.ColorContent }"
        >
          {{$t('dtouch.site.email', locale)}} : 
        </span> 
        <a :href="`mailto:${content.SiteEmail}`"
          style="text-decoration: none;"
          :style="{ 'color': config.ColorContent }"
        >
          {{content.SiteEmail}}
        </a>
      </div>

      <v-card-actions v-if="moreInfo && moreInfo.length > 0">
          <v-btn
            dark      
            block
            :color="'#CCCCCC'"
            @click="show = !show"
            style="margin-bottom:5px;"
          >
            {{ $t(show ? 'dtouch.site.hideMoreInfo' : 'dtouch.site.showMoreInfo', locale) }}
          </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show && moreInfo && moreInfo.length > 0">
          <!-- <v-card-text> -->
            <div
              class="result-html"
              
            >
              <vue-editor 
                v-model="moreInfo"
                :disabled="true"
                :editor-toolbar="aux"
                
                :style="{ 
                  'color': `${config.ColorContent} !important`,
                  }"
              />
            </div>
          <!-- </v-card-text> -->
        </div>
      </v-expand-transition>

      <div
        v-if="content.SiteLink"
        style="padding:10px;"
      >
        <v-btn
          v-if="!content.SiteLinkDialog"
          color="primary"
          dark
          @click="handleGoTo(getTranslation(content.SiteLinkURL, locale))"
          block
        >
          {{ getTranslation(content.SiteLinkName, locale) }}
        </v-btn>
        <v-dialog
          v-else
          v-model="dialogSite"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
              block
            >
              {{ getTranslation(content.SiteLinkName, locale) }}
            </v-btn>
          </template>
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="dialogSite = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{getTranslation(content.SiteLinkName, locale)}}</v-toolbar-title>
            </v-toolbar>
            <iframe
              v-if="content.SiteLinkURL"
              :src="getTranslation(content.SiteLinkURL, locale)" 
              width="100%"
              height="1200"
            /> 
          </v-card>
        </v-dialog>
      </div>
    </v-card>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import utils from '@/services/utils'
export default {
  name:'Item20Site',
  components: {
    VueEditor
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    show: false,
    aux: [[],[],[],[],[],[],[],[],[],[],[],[],[{  }],[]],
    dialogSite: false,
  }),
  computed: {
    title () {
      return this.content.SiteTitle ? utils.getTranslation (this.content.SiteTitle, this.locale) : ''
    },
    subtitle () {
      return this.content.SiteSubtitle ? utils.getTranslation (this.content.SiteSubtitle, this.locale) : ''
    },
    moreInfo () {
      return this.content.SiteMoreInfo ? utils.getTranslation (this.content.SiteMoreInfo, this.locale) : ''
    },
    coordenates () {
      return `https://maps.google.com/?q=${this.content.SiteLat}%2C${this.content.SiteLon}`
    },
  },
  methods: {
    getTranslation (v, locale) {
      return v ? utils.getTranslation(v, locale) : ''
    },
    handleGoTo (v) {
      window.open(
        v,
        '_blank' // <- This is what makes it open in a new window.
      )
    },
    handleGoToMap (url) {
      window.open(
        url,
        '_blank' // <- This is what makes it open in a new window.
      )
    },
  },
}
</script>
<style>
.result-html .ql-toolbar.ql-snow {
  height: 0px !important;
  padding: 0px !important;
  border: 0px !important;
}
.result-html .ql-container.ql-snow {
  border: 0px !important;
}
.result-html .ql-editor {
  min-height: 0px !important;
}  
.siteTitle {
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: normal;     /* Sólo WebKit -NO DOCUMENTADO */

  -ms-hyphens: auto;          /* Guiones para separar en sílabas */
  -moz-hyphens: auto;         /*  depende de lang en <html>      */
  -webkit-hyphens: auto;
  hyphens: auto;
}
</style>

