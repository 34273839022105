<template>
  <div>
    <button 
      style="border-radius:50%;text-align:center;padding:5px 5px 2px 5px;opacity:0.7;height:34px;width:34px;"
      :style="{ 'background-color': active ? 'rgb(85,173,11)' : color}"
      :disabled="active || isSimulation"
      @click.stop="handleShowCalendar"
    >
      <v-icon
        style="margin-left: 0px; color: white;margin-top:-5px;"
        dark
        class="activity-button"
      >
        {{ active ? 'mdi-calendar-clock' : 'mdi-calendar-plus' }}
      </v-icon>
    </button>
    <v-dialog
      :content-class="'addToCalendar'"
      v-model="showAddToCalendar"
      style="max-width: 100px !important;"
    >
      <v-card style="border-radius:5px;">
        <!-- <v-card-title
          class="headline"
          style="text-transform: uppercase;text-align: center; width: 100%; font-size: 20px;"
          :style="{ 'color': color }"
        >
          {{ $t('messages.addToCalendar') }}
        </v-card-title> -->

        <v-card-text style="padding: 30px 50px 30px 50px;">
          <div style="width:100%;text-align: right;">
            <button 
            style="margin-right:-30px;margin-top:-10px;"
              @click="showAddToCalendar = false"
            >
              <v-icon
                style="margin-left: 0px; color: white;font-size:30px;"
                :style="{ 'color': color }"
                class="activity-button"
              >
                mdi-close
              </v-icon>
            </button>
          </div>
          <center style="margin-bottom: 20px;">
            <button 
            disabled="true"
            style="border-radius:50%;height: 80px;width:80px;text-align:center;padding:5px 5px 2px 5px;opacity:0.7;"
            :style="{ 'background-color': color}"
          >
            <v-icon
              style="margin-left: 0px; color: white;font-size:50px;"
              dark
              class="activity-button"
            >
              mdi-calendar
            </v-icon>
          </button>
          </center>
          <center 
            style="text-transform: uppercase;font-size: 18px;margin-bottom: 5px;"
            :style="{ 'color': color }"
          >{{ $t('common.addToCalendar', locale) }}</center>
          <center
            style="text-transform: uppercase;font-size: 10px;margin-bottom: 0px;"
            :style="{ 'color': color }"
          >
            {{ title }}
          </center>
          <center 
            style="text-transform: uppercase;font-size: 15px;margin-bottom: 10px;"
            :style="{ 'color': color }"
          >
            <v-icon
              dark
              class="activity-button"
              style="margin-left: 0px;font-weight: 500;font-size: 17px;"
              :style="{ 'color': color}"
            >
              mdi-clock-outline
            </v-icon> <b>{{ startTime.substring(0, 5) }} - {{ endTime.substring(0, 5) }}</b>
          </center>
          <div
          style="width: 100%; text-align:center;"
            v-if="addToCalendarButtons"
            v-html="addToCalendarButtons"
          />
          <br>
          <center style="opacity: 0.3; font-size: 12px;">Powered by <b>Descubre Group®</b></center>
          <!-- <div id="new-cal" class="new-cal"></div> -->
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'AddCalendar',
  props: {
    locale: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    description: {
      type: String,
      required: false,
      default: ''
    },
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
    startTime: {
      type: String,
      required: true,
    },
    endTime: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'black'
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    isSimulation: {
      type: Boolean,
      defautl: true,
    },
  },
  data() {
    return {
      showAddToCalendar: false,
      addToCalendarButtons: ''
    }
  },
  methods: {
    handleShowCalendar () {
      const start = new Date(Date.parse(this.start, 'Y-m-d'))
      const end = new Date(Date.parse(this.end, 'Y-m-d'))

      const startTime = this.startTime.split(':')
      start.setHours(startTime[0], startTime[1], '00')
      const endTime = this.endTime.split(':')
      end.setHours(endTime[0], endTime[1], '00')
      const duration = parseInt((end - start) / (1000 * 60), 10)

      // eslint-disable-next-line
      const myCalendar = createCalendar({
        options: {
          class: 'my-class',
          id: 'my-id'
        },
        data: {
          title: this.title,
          start: start,
          duration: duration,
          end: end,
          address: '',
          description: this.description
        }
      })
      let aux = myCalendar.replace(new RegExp('######', 'g'), this.color)
      this.addToCalendarButtons = aux
      this.showAddToCalendar = true
    }
  }
}
</script>
<style>
.addToCalendar {
  max-width: 600px!important;
  width: auto;
}
.btnAdd {
  border: 1px solid!important;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  color: red;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 2px;
}
.btnAdd:hover {
  border: 1px solid !important;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 10px;
  color: red;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 2px;
  background-color: #d4d9dc;
}
</style>

