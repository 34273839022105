<template>
 <v-layout
    justify-center
    class="activity"
  >
    <v-flex
      xs12
      style="padding-bottom: 0px;padding-top: 0px;padding-left: 20px;padding-right: 20px;"
    >
      <div
        style="padding-top: 15px; padding-bottom: 15px;font-size:22px;"
        class="text-center"
      >
        {{ $t(sDay.pre).toUpperCase() }} {{ $t(sDay.month).toUpperCase() }} {{ sDay.pos.toUpperCase() }}
      </div>
      <div
        style="border-radius:5px;position: static; top:0px;margin-bottom:20px;margin-top:20px;"
        :style="{ 'background-color': `${primaryColor}` }"
      >
        <v-btn-toggle v-model="daysToAdd" style="width: 100%;"
          :style="{ 'background-color': `${secundaryColor}` }"
        >
          <v-btn
            v-for="(day, index) in week"
            :key="`week${index}`"
            style="width:14.3%;min-width:14.2%;"
            :style="{ 'color': secundaryColor }"
            @click="() => handleAddDays(index)"
            :disabled="index === daysToAdd"
            :dark="!isLight(secundaryColor)"
            :light="isLight(secundaryColor)"
          >
           <span :style="{ 'color': isLight(secundaryColor) ? 'black' : 'white' }">{{ $t(`common.${day}`, locale).substring(0, 2) }}</span>
           <!-- {{ $t(`common.${day}`).substring(0, 2) }} -->
          </v-btn>          
        </v-btn-toggle>
      </div>
      <!-- <div
        style="opacity: 0.8;position: static; height:64px;top:0px;margin-bottom:20px;"
        :style="{ 'background-color': `${BGColor}` }"
      >
        <table border="0" style="width:100%;height:100%;border:0px;" cellspacing="0" cellpadding="0">
          <tr>
            <td class="td" v-for="(day, index) in week" 
              :key="`td${index}`"
              :style="{ 'padding-top': isSimulation || isDesktop ? '20px' : '','background-color': daysToAdd === index ? ColorContent : BGColor, 'border-right': index !== 6 ? '1px solid white' : '0px' }" 
              style="text-align: center;color:white;width:14.19%"
              @click="() => handleAddDays(index)"
            >
              {{ $t(`common.${day}`).substring(0, 2) }}
            </td>
          </tr>
        </table>
      </div> -->
      <v-container
        fluid
        grid-list-md
      >
        <v-layout
          row
          wrap
          style="margin-bottom: 20px;"
        >
          <v-flex
            v-if="!shows || shows.length === 0"
            :style="{ 'background-color': ColorContent, 'color': BGColorContent }"
            style="margin: 15px;text-align: center;padding-top:10px;padding-bottom:10px;opacity:0.9;"
          >
            {{ $t('activity.messages.noActivities').toUpperCase() }}
          </v-flex>
          <v-flex
            v-for="(show) in shows"
            :key="show.ID"
            v-bind="{ [`xs${isDesktop ? 12 : 12}`]: true }"
            style="margin-top: 5px;margin-bottom: 6px;"
          >
            <!-- {{index}}
            <br>
            {{show}} -->
            <v-card
              class="mx-auto"
              max-width="344px"
              :style="{ 'opacity': show.Availability.finished ? '0.3' : '1' }"
            >
              <v-img
                :src="show.Image"
                height="auto"
              ></v-img>
              <v-card-title>
                <v-layout
                  row
                  wrap
                  style="margin-top:5px;margin-bottom:0px;"
                >
                  <v-flex
                    xs8
                    style="font-size: 16px;line-height:1.2;"
                    :style="{ 'color': titleColor}"
                  >
                    {{ show.Title.toUpperCase() }}
                  </v-flex>
                  <v-flex
                    xs4
                    style="text-align: right;padding-right: 15px;padding-bottom:0px;"
                  >
                    <add-calendar
                      v-if="!isSimulation && show && !show.Availability.finished"
                      :start="show.Date"
                      :end="show.Date"
                      :startTime="show.TimeStart"
                      :endTime="show.TimeEnd"
                      :title="show.Title"
                      :description="show.Description ? show.Description : ''"
                      :color="secundaryColor"                      
                      :active="daysToAdd === 0 && show.Availability.time"
                    />
                  </v-flex>
                </v-layout>
                <div style="width: 100%">
                  <v-icon
                    :style="{ 'color': show.Availability.time && show.Availability.daysToAdd === '0' ? 'rgb(85,173,11)' : ColorContent}"                  
                    dark
                    class="activity-button"
                    style="margin-left: 0px;font-weight: 500;font-size: 17px;"
                  >
                    mdi-clock-outline
                  </v-icon>
                  <span
                    :style="{ 'color': show.Availability.time && show.Availability.daysToAdd === '0' ? 'rgb(85,173,11)' : ColorContent}"                  
                    style="margin-left: 0;font-weight: 500;font-size: 17px;"
                  >
                    {{ show.TimeStart.substring(0, 5) }}-{{ show.TimeEnd.substring(0, 5) }}
                  </span>
                </div>
                <div style="width: 100%">
                  <span
                    style="margin-left: 0;font-size: 13px;"
                    :style="{ 'color': ColorContent}" 
                  >{{ show.Site }}</span>
                </div>
                <div
                  style="margin-bottom: 5px;margin-left: 0;font-size: 13px;width: 100%;line-height:normal" 
                  v-if="show.Description && show.Description.length > 0"
                  :style="{ 'color': `${descriptionColor} !important`}"
                >
                  {{ show.Description }}
                </div>
              </v-card-title>              
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-flex>
  </v-layout>
</template>
<script>
import tinycolor from 'tinycolor2'
import utils from '@/services/utils'
import api from '@/services/api'
import AddCalendar from './AddCalendar'
export default {
  name:'Item19Show',
  components: {
    AddCalendar,
  },
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dviewcontentID: null,
    shows: null,
    daysToAdd: 0,
    week: [],
    typesActivity: null,
    typeActivity: 0,
    typeOriginalActivity: null,
  }),
  computed: {
    isSimulation () {
      return false
    },
    workspaceID () {
      return utils.getWorkspace('dtouch')
    },
    sDate () {
      return `${this.$t(this.sDay.pre, this.locale).toUpperCase()} ${this.$t(this.sDay.month, this.locale).toUpperCase()} ${this.sDay.pos.toUpperCase()}`
    },
    sDay () {
      let dToday = new Date()
      const dActual = new Date().setDate(dToday.getDate() + this.daysToAdd)
      const pos = `${new Date(dActual).getDate()}, ${new Date(dActual).getFullYear()}`

      const result = {
        pre: this.daysToAdd === 0 ? 'common.today' : this.daysToAdd === 1 ? 'common.tomorrow' : '',
        month: `common.m${new Date(dActual).getMonth()}`,
        pos
      }
      return result
    },
    enterpriseColor () {
      return this.config.BGColor
    },
    BGColorContent () {
      return this.config.BGColorContent
    },
    ColorContent () {
      return this.config.ColorContent
    },
    primaryColor () {
      return this.content.ShowPrimaryColor ? this.content.ShowPrimaryColor : this.config.BGColor
    },
    secundaryColor () {
      return this.content.ShowSecundaryColor ? this.content.ShowSecundaryColor : this.config.BGColorSecundary
    },
    titleColor () {
      return this.content.ShowColor ? this.content.ShowColor : this.ColorContent
    },
    colorShowContent () {
      return this.content.ShowSecundaryColor ? this.content.ShowSecundaryColor : this.ColorContent
    },
    descriptionColor () {
      return this.content.ShowDescriptionColor ? this.content.ShowDescriptionColor : this.ColorContent
    },
  },
  watch: {
  },
  mounted () {
    this.init ()
    //this.handleGetTypes()
  },
  methods: {
    init () {
      this.typeActivity = this.content.TypeShow ? this.content.TypeShow : 0
      if(this.typeActivity >= 0) this.typeOriginalActivity = this.typeActivity
      const dSemenaActual = new Date().getUTCDay() - 1
      const aux = []
      for (let i = 0; i < 7; i++) {
        let x = ((dSemenaActual + i) >= 7) ? (dSemenaActual + i - 7) : dSemenaActual + i
        if (x === -1) x = 6
        aux.push(`s${x}`)
      }
      this.week = aux
      this.handleGetShows()
    },
    isLight (value) {
      return tinycolor(value).isLight()
    },
    handleGetTypes () {
      api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/show-types`)
        .then(response => {
          this.typesActivity = response.types
          this.dviewcontentID = response.dviewcontentID
          this.handleGetActivities()
        })
    },
    handleAddDays (value) {
      this.daysToAdd = value
      this.handleGetShows()
    },
    handleChangeType() {
      this.handleGetShows()
    },
    handleGetShows () {
      api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/show-dviewcontent/${this.daysToAdd}/${this.typeActivity}`)
        .then(response => {
          if (response.data && response.data.items) {
            this.shows = response.data.items.map((item) => {
              // se hace esto ya que ponen saltos de línea en dviewcontent para el html de los players
              item.Site = utils.replaceAll(item.Site, '<br>',' ')
              item.Site = utils.replaceAll(item.Site, '</br>',' ')
              item.Site = utils.replaceAll(item.Site, '<BR>',' ')
              item.Site = utils.replaceAll(item.Site, '</BR>',' ')
              return item
            })
          } else this.shows = []
          this.shows = response.data && response.data.items ? response.data.items : []
        })
    },
  },
}
</script>

