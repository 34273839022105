<template>
   <v-layout wrap>
      <v-flex xs12>
         <center>
         <v-progress-linear
            v-if="loading"
            indeterminate
            :color="content.BookingActWeekPrimaryColor"
         />
         <v-date-picker
            no-title
            v-model="selectedDate"
            :events="functionEvents"
            :picker-date.sync="pickerDate"
            @change="handleChangeDate"
            :locale="locale"
            :min="min"
            :disabled="loading"
            :elevation="0"
            :flat="true"
            first-day-of-week="1"
         />
         </center>
      </v-flex>
      <v-flex xs12
         style="padding: 10px;text-align: center;"
      >
         <reservation-form
            v-for="item of hours"
            :key="item.id"
            :event="item"
            :locale="locale"
            :color="content.BookingActTimeColor"
            :timeLimit="selectedActivity.TimeLimit !== null"
            style="margin-right: 10px;"
         />
      </v-flex>
      <v-flex v-if="timeLimit" style="padding: 20px;font-style: italic;line-height:1;">
            <small v-html="timeLimit" />
      </v-flex>
   </v-layout>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import utils from '@/services/utils'
import ReservationForm from './ReservationForm'
export default {
   components: {
      ReservationForm,
   },
   props: {
      selectedActivity: {
         type: Object,
         required: true,
      },
      locale: {
         type: String,
         required: true,
      },
      content: {
         type: Object,
         required: true,
      },
      config: {
         type: Object,
         required: true,
      },
   },
   data: () => ({
      loading: false,
      dates: [],
      hours: null,
      selectedDate: null,
      pickerDate: null,
      selectedTime: null,
   }),
   computed: {
      ...mapState('app', ['dtouchWorkspace']),
      min () {
         return new Date().toISOString().split('T').shift()
      },
      timeLimit () {
         const t = this.selectedActivity.TimeLimit
         if (!t) return

         const aux = []
         if (t.from) {
            aux.push(`${this.$t(`booking.typeLimit.from`, this.locale)} ${this.prepareLimit(t.from.limit, t.from.type)}`)
         }
         if (t.to) {
            aux.push(`${this.$t(`booking.typeLimit.to`, this.locale)} ${this.prepareLimit(t.to.limit, t.to.type)}`)
         }
         return `(*) ${this.$t(`booking.typeLimit.canBeReserved`, this.locale)} ${aux.join(', ')} ${this.$t(`booking.typeLimit.before`, this.locale)}.`
      },
   },
   watch: {
      pickerDate () {
         this.handleGetDates()
      },
   },
   mounted () {
      this.handleGetDates()
   },
   methods: {
      convertMinutes (num) {
         const d = Math.floor(num/1440) // 60*24
         const h = Math.floor((num-(d*1440))/60)
         const m = Math.round(num%60)

         let result = []
         if (d > 0) result.push(`${d} días`)
         if (h > 0) result.push(`${h} horas`)
         if (m > 0) result.push(`${m} minutos`)

         return result.join(', ')
      },
      prepareLimit (limit, type) {
         if (type === 'minutes') {
            limit = this.convertMinutes(limit)
            return limit
         } else return `${limit} ${this.$t(`booking.typeLimit.${type}`, this.locale)}`
      },
      parseJSON (v) {
         return typeof v === 'string' ? JSON.parse(v) : v
      },
      getTranslations (v, locale) {
         return utils.getTranslation(v, locale)
      },
      checkAllowReservations (v) {
         const withEvents = this.dates.filter(x => x.date === v)
         if (withEvents.length === 0) return null
         if(withEvents.filter(x => x.AllowReservations).length > 0) return true
         return false
      },
      functionEvents (v) {
         let result = null
         const aux = this.checkAllowReservations(v)
         if (aux === null) result = null
         else if (aux) result = [this.content.BookingActTimeColor]
         else result =  ['#ccc']
         return result
      },
      handleGetDates () {
         this.handleSelectTime()
         this.loading = true
         const url = `${window.location.protocol}//${process.env.VUE_APP_API_URL_DTOUCH}/v1/public/cm/${this.dtouchWorkspace}/activities/${this.selectedActivity.ID}/events`
         const filter = {
            source: this.selectedActivity.Source,
            month: this.pickerDate
         }
         axios.post(url, filter)
            .then((response) => {
               this.dates = response.data.map(item => {
                  item.sTime = utils.convertTime(item.Time)
                  item.date = item.Date.split('T').shift()
                  return item
               })
               this.selectedDate = new Date().toISOString().split('T').shift()
               this.handleChangeDate()

            })
            .catch((error) => {
               console.log(error)
            })
            .then(() => {
               this.loading = false
            })
      },
      handleChangeDate () {
         this.handleSelectTime()
         this.hours = this.dates.filter(x => x.date === this.selectedDate)
      },
      handleSelectTime (v) {
         if(!v) return
         this.selectedTime = v.id
      },
   },
}
</script>

<style scoped>
.v-btn__content {
   z-index: 1;
}
.v-date-picker-table__events .yellow {
   height: 34px;
   width: 34px;
   margin-top: -19px;
   margin-left: -2px;
   border-radius: 4px;
   background-color: var(--time-bg-color) !important;
}
.v-application .yellow {
   height: 34px;
   width: 34px;
   margin-top: -19px;
   margin-left: -2px;
   border-radius: 4px;
   background-color: var(--time-bg-color) !important;
}
.v-card  {
   /*
   background-color: blue !important;
   transition-property: none !important;
   transition: none !important;
   outline: none !important;
   border-width: thin !important;*/
   box-shadow: none;
}
</style>

